import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { MainSection } from '../../layout/mainSection/MainSection'
import { Unknown } from '../unknown/Unknown'
import { Verification } from '../verification/Verification'
import { Display } from '../display/Display'

export default function Dashboard() {
    return (
        <>
            <MainSection>
                <Router>
                    <Switch>
                        <Route path="/v/:id" component={ Display } />
                        <Route path="/" component={ Unknown } />
                    </Switch>
                </Router>
            </MainSection>
        </>
    )
}
