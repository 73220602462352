import { Button, Heading, SimpleGrid, useColorModeValue, Text, Img, Center, Flex, Divider, HStack, Tooltip, IconButton, Link, Box, Wrap, WrapItem, Stack, LinkBox, LinkOverlay, Skeleton, Spacer } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaBriefcase, FaCalendar, FaCalendarDay, FaFacebook, FaInfo, FaInfoCircle, FaInstagram, FaLinkedin, FaTwitter, FaUser } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { Card } from '../../components/card/Card'
import { Feature } from '../../components/feature/Feature'
import { PoweredBy } from '../../components/poweredBy/PoweredBy'
import supabase from '../../configs/Supabase'
import itscLogo from '../../assets/images/itsc_logo.png'
import verified from '../../assets/images/verified.png'
import verifiedRed from '../../assets/images/verified_red.png'

export const Display = () => {
    //@ts-ignore
    const { id } = useParams()
    const [verificationData, setVerificationData] = useState<object[] | null>(null)
    const [certificateDataFromSupabase, setCertificateDataFromSupabase] = useState<object[] | null>(null)
    const textColor = useColorModeValue('gray.600', 'gray.400')
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        const { data, error } = await supabase
                                    .rpc('get_certificate_data_for_verification', { certificate_id: id, certificate_short_id: id })
        const { data:certiData, error:certiError } = await supabase.from('certificates').select(`extra_metadata`).eq('id', id)
        if (error || certiError) {
            setIsLoading(false);
            return
        } else {
            setVerificationData(data)
            setCertificateDataFromSupabase(certiData)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData()
    }, [supabase])

    if ( verificationData && verificationData.length > 0 && certificateDataFromSupabase && certificateDataFromSupabase.length > 0 ) {
        const certificateData = verificationData[0] as {
            id: string,
            certificate_short_code: string,
            name: string,
            type: string,
            metadata: JSON,
            organization_name: string,
            website: string,
            linkedin: string,
            organization_type: string,
            twitter: string,
            facebook: string,
            instagram: string,
            about_organization: string,
            logo_url: string,
            event_name: string,
            event_type: string,
            start_date: Date,
            end_date: Date
        }

        console.log(certificateDataFromSupabase);

        const testKindMapping = {
                'GST' :'G-TELP Speaking Test',
                'GWT' :'G-TELP Writing Test',
                'GBST' :'G-TELP Business Speaking Test',
                'GBWT' :'G-TELP Business Writing Test',
                'GLT-L1' :'GLT Level 1',
                'GLT-L2' :'GLT Level 2',
                'GLT-L3' :'GLT Level 3',
                'GLT-L4' :'GLT Level 4',
                'GLT-L5' :'GLT Level 5',
                'G-TELP-JL1' :'G-TELP Jr. Level 1',
                'G-TELP-JL2' :'G-TELP Jr. Level 2',
                'G-TELP-JL3' :'G-TELP Jr. Level 3',
                'G-TELP-JL4' :'G-TELP Jr. Level 4',
                'G-TELP-JL5' :'G-TELP Jr. Level 5'
        }
        
        return (
            <>
                <main>
                    <div className="title">
                    <img src={itscLogo} alt="ITSC Logo" /><h2>Test Score Verification</h2>
                    </div>
                    <hr className="hr-t" />
                    <div className="content-wrap">
                    <div className="verified">
                        <img src={verified} alt="verified mark" />
                    </div>

                    <div className="content">
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2} className="t-t">Examinee Name</td>
                                    <td className="t-c">{certificateData.name}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className="t-t">Date of Birth</td>
                                    <td className="t-c">{certificateDataFromSupabase[0].extra_metadata.dob}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className="t-t">Test Name</td>
                                    <td className="t-c">{testKindMapping[certificateDataFromSupabase[0].extra_metadata.test_kind] || 'G-TELP TEST'}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className="t-t">Test Date</td>
                                    <td className="t-c">{certificateDataFromSupabase[0].extra_metadata.test_date}</td>
                                </tr>
                                <tr>
                                    <td rowSpan={2} className="t-t">Test Results</td>
                                    <td className="t-t">Overall Score</td>
                                    <td className="t-c">{certificateDataFromSupabase[0].extra_metadata.total_percent}</td>
                                </tr>
                                <tr>
                                    <td className="t-t">Level Achieved</td>
                                    <td className="t-c">{certificateDataFromSupabase[0].extra_metadata.level_achieved}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>

                    <hr className="hr-b" />
                    <div className="link">
                    <a href="https://www.g-telp.com/" target="_blank">www.g-telp.com</a>
                    <a href="https://www.itsc-group.com/" target="_blank">www.itsc-group.com</a>
                    </div>
                </main>
            </>
        )
    }

    if (isLoading) {
		return (
            <Center>
                <Skeleton height="100px" />
            </Center>
        )
	}

    return (
        <Center>
            <Card w={['90%', null, '60%', '60%']}>
                <Center w="100%" h="100%" mb="5">
                    <Img
                        htmlWidth="150px"
                        htmlHeight="150px"
                        height='150px'
                        objectFit="cover"
                        src="https://thumbs.gfycat.com/SpecificSlimIndianringneckparakeet-small.gif"
                        alt="Certificate Not found"
                    />
                </Center>
                <Divider my={5} />
                <Box textAlign="center">
                    <Heading size="lg" mb="5">
                        Certificate Not Found
                    </Heading>
                    <Text>
                        We are unable to find any certificate associated with the provided certificate id. If you 
                        think its a mistake contact us at {" "}
                        <Link 
                            href="mailto:support@givemycertificate.com"
                        >
                            support@givemycertificate.com
                        </Link>
                    </Text>
                </Box>
                <PoweredBy />
            </Card>
        </Center>
    )
}
